import { EnvironmentInstance, EnvironmentName, TenantId } from 'src/app/_core/constants/EnvironmentConst';
import { Environment } from 'src/app/_core/models/IEnvironment';

export const environment: Environment = {
  name: EnvironmentName.ENVIRONMENTNAME,
  tenantId: TenantId.TENANTID,
  environmentInstance: EnvironmentInstance.TEST,
  defaultLanguage: 'CONSTANT_DEFAULTLANGUAGE',
  appVersion: require('../../package.json').version,
  production: true,
  url: 'CONSTANT_URL',
  server: 'CONSTANT_SERVER',
  authServer: 'CONSTANT_AUTHSERVER',
  mattermostUrl: 'CONSTANT_MATTERMOSTURL',
  secure: true,
  changeOrigin: true,
  placesApiKey: 'CONSTANT_PLACESAPIKEY',
  recaptchaApiKey: 'CONSTANT_RECAPTCHAKEY',
  authBackground: 'CONSTANT_AUTHBACKGROUND',
  whiteLogo: 'CONSTANT_WHITELOGO',
  blackLogo: 'CONSTANT_BLACKLOGO',
  favicon: 'CONSTANT_FAVICON',
  termsOfUse: 'CONSTANT_TERMSOFUSE',
  privacyPolicy: 'CONSTANT_PRIVACYPOLICY',
  emailAddress: 'CONSTANT_EMAILADDRESS',
  hasCause: true,
  hasCodeOfConduct: true,
  isEnglishDefault: true,
  authConfig: {
    issuer: 'CONSTANT_AUTHISSUER',
    redirectUri: window.location.origin,
    clientId: 'CONSTANT_AUTHCLIENTID',
    responseType: 'CONSTANT_AUTHRESPONSETYPE',
    scope: 'CONSTANT_AUTHSCOPE',
    showDebugInformation: true,
    disableAtHashCheck: true,
    sessionChecksEnabled: true,
    useSilentRefresh: true,
    silentRefreshTimeout: 5000,
  },
  keycloakRealm: 'CONSTANT_KEYCLOAKREALM',
  unleashConfig: {
    clientKey: 'CONSTANT_UNLEASHCLIENTKEY',
    url: 'CONSTANT_UNLEASHURL',
  },
};
